<template>
  <v-container fill-height align="center">
    <v-row justify="center">
      <v-data-table
        :headers="headers"
        :items="events"
        :items-per-page="10"
        item-key="code"
        sort-by="name"
        mobile-breakpoint="10"
        class="table-cursor elevation-1 mt-3"
        :loading="loading_status"
        loader-height="10"
        loading-text="Cargando ..."
      >
      </v-data-table>
      <v-bottom-navigation grow app>
        <v-btn @click="dialog = !dialog">
          <span>Nuevo</span>
          <v-icon>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-row>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form ref="itemForm">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar>
              <v-app-bar-title>Cuenta de acceso</v-app-bar-title>
              <v-spacer></v-spacer>
              <v-btn x-small color="info" fab @click="dialog = !dialog">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-col cols="12" md="12">
              <v-select
                v-model="event.tanque"
                ref="user_access"
                :items="tanques"
                item-text="name"
                item-value="name"
                label="Tanque"
                outlined
                hide-details="auto"
                return-object
                required
                @change="updateForm"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="event.food_type"
                label="Tipo de alimento"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                v-model="event.foodQty"
                label="Cantidad de alimento"
                hide-details="auto"
                outlined
                :suffix="event.uom"
              ></v-text-field>
            </v-col>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
export default {
  components: {},
  data: () => ({
    loading_status: false,
    table: "stores",
    dialog: false,
    calculator_dialog: false,
    event: { tanque: null },
    f_required: [(v) => !!v || "Requerido"],
    events: [],
    headers: [
      {
        text: "Fecha",
        align: "start",
        sortable: true,
        value: "isssueDate",
        dataType: "text",
      },
      {
        text: "Tanque",
        align: "start",
        sortable: true,
        value: "name",
        dataType: "text",
      },
      {
        text: "Tipo Alimento",
        align: "start",
        sortable: true,
        value: "food_type",
        dataType: "text",
      },
      {
        text: "Cantidad",
        align: "start",
        sortable: true,
        value: "quantity",
        dataType: "text",
      },
    ],
    items: [],
    errors: [],
    tanques: [
      {
        name: "Tanque 1",
        fishQty: 1000,
        food_type: "INICIACION",
        feeds: 3,
        foodQty: 10,
        uom: "KG",
      },
      {
        name: "Tanque 1-1",
        qty: 1000,
        food_type: "INICIACION",
        feeds: 3,
        foodQty: 10,
        uom: "KG",
      },
      {
        name: "Tanque 1-2",
        qty: 1000,
        food_type: "INICIACION",
        feeds: 3,
        foodQty: 10,
        uom: "KG",
      },
      {
        name: "Tanque 3",
        qty: 1000,
        food_type: "LEVANTE",
        feeds: 3,
        foodQty: 10,
        uom: "KG",
      },
      {
        name: "Tanque 4",
        qty: 1000,
        food_type: "LEVANTE",
        feeds: 3,
        foodQty: 10,
        uom: "KG",
      },
      {
        name: "Tanque 5",
        qty: 1000,
        food_type: "ENGORDE",
        feeds: 3,
        foodQty: 10,
        uom: "KG",
      },
    ],
  }),
  methods: {
    updateForm(e) {
      this.event = e;
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.items_bygroup = data.reduce(function (r, a) {
          r[a.group_name] = r[a.group_name] || [];
          r[a.group_name].push(a);
          return r;
        }, Object.create(null));

        this.groups = Object.keys(this.items_bygroup).sort();

        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
  },
  mounted() {
    this.get_items();
  },
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>